<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>Report Columna</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.brandId"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brands"
                    :loading="isSearching['brands']"
                    :items="filtered_brands"
                    hide-no-data
                    hide-selected
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                    @change="onChangeFilterMainCredentials('surveys')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.from"
                        v-bind:label="$t('brands.start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.from"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="endDate_menu"
                    v-model="select_endDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.to"
                        v-bind:label="$t('brands.end_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.to"
                      no-title
                      scrollable
                      @input="select_endDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idSurveys"
                    v-bind:label="$t('survey')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_surveys"
                    :loading="isSearching['surveys']"
                    :items="filtered_surveys"
                    hide-no-data
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idSPVs"
                    v-bind:label="`SPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_spvs"
                    :loading="isSearching['spvs']"
                    :items="filtered_spvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    @change="onChangeFilterMainCredentials('spvs')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idRoutes"
                    v-bind:label="$t('route')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_routes"
                    :loading="isSearching['routes']"
                    :items="filtered_routes"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idGPVs"
                    v-bind:label="`GPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_gpvs"
                    :loading="isSearching['gpvs']"
                    :items="filtered_gpvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.companyCodes"
                    :label="$t('Company')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_companies"
                    :loading="isSearching['companies']"
                    :items="filtered_companies"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col cols="12" sm="12" md="12">
            <v-btn color="blue darken-1 mr-2" @click="onClickFilter">
              {{ $t("go") }}
            </v-btn>
            <v-btn color="warning" dark @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <b-alert show variant="info" v-if="isLoadingTableData">
          <b-icon
            icon="arrow-counterclockwise"
            animation="spin-reverse"
          ></b-icon>
          cargando datos...
        </b-alert>
        <v-row v-else-if="!isLoadingTableData && !showEmpty">
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefSales"
              id="datatable_reporting_columna"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
        <b-alert v-else variant="warning" class="m-0 mt-4 p-2 my-alert" show>
          <i> No hay datos....</i>
        </b-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";

export default {
  name: "ReportingColumna",
  data: function() {
    return {
      filterlist_fetch_url: "reporting/columna/getfilterlist",
      filter_main_credentials_fetch_url:
        "reporting/columna/getfiltermaincredentials",
      filterable_col_list: [],
      filterCriteria: {
        route: {},
        date: {},
        user: {},
        pos: {},
        address_label: "",
        chain: {},
        subchain: {},
        sub2chain: {},
        channel: {},
        subchannel: {},
        sub2channel: {},
        brand: {},
        product: {},
        unit_price_without_vat: {},
        euros_sales: {},
        total_pos_sales: {},
        total_pos_sales_objective: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 10,
      pageOptions: [10, 20],
      totaldata: 0,
      data: [],
      isLoadingTableData: false,
      merged_surveys: {},
      foundOriginalSurveys: [],

      columns: [],
      isMockupColumns: false,
      MAX_DAYS_COUNT_DISPLAY: 7,

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],

      select_startDate_menu: false,
      select_endDate_menu: false,
      isSearching: {},
      search_brands: null,
      filtered_brands: [],
      search_surveys: null,
      filtered_surveys: [],
      search_spvs: null,
      filtered_spvs: [],
      search_gpvs: null,
      filtered_gpvs: [],
      search_routes: null,
      filtered_routes: [],
      search_companies: null,
      filtered_companies: [],

      filterMainCredential: {
        companyCodes: [],
        brandId: null,
        date: {
          from: null,
          to: null
        },
        idSurveys: null,
        idSPVs: [],
        idRoutes: [],
        idGPVs: []
      }
    };
  },
  watch: {
    async search_companies(val) {
      this.searchValues("companies", val);
    },
    async search_brands(val) {
      this.searchValues("brands", val);
    },
    async search_surveys(val) {
      this.searchValues("surveys", val);
    },
    async search_routes(val) {
      this.searchValues("routes", val);
    },
    async search_spvs(val) {
      this.searchValues("spvs", val);
    },
    async search_gpvs(val) {
      this.searchValues("gpvs", val);
    }
  },
  computed: {
    showEmpty() {
      let ret = true;
      if (this.data.length > 0) {
        ret = false;
      }
      return ret;
    },
    col_routeId() {
      return {
        key: "routeId",
        title: "ID " + this.$t("route"),
        field: "routeId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_route() {
      return {
        key: "route_name",
        title: this.$t("route"),
        field: "route_name",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route_name}</span>;
        }
      };
    },
    col_date() {
      return {
        key: "worksessionDate",
        title: this.$t("Date"),
        field: "worksessionDate",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.worksessionDate}</span>;
        }
      };
    },
    col_userId() {
      return {
        key: "userId",
        title: "ID " + this.$t("User"),
        field: "userId",
        align: "left",
        width: 120,
        sortBy: ""
      };
    },
    col_user() {
      return {
        key: "user_name",
        title: this.$t("User"),
        field: "user_name",
        align: "left",
        width: 200,
        sortBy: ""
      };
    },
    col_responsableId() {
      return {
        key: "responsableId",
        title: `ID ${this.$t("salesforce.Parent")}`,
        field: "responsableId",
        align: "left",
        width: 120,
        sortBy: ""
      };
    },
    col_responsable_name() {
      return {
        key: "responsable_name",
        title: `${this.$t("salesforce.Parent")} ${this.$t("salesforce.name")}`,
        field: "responsable_name",
        align: "left",
        width: 200,
        sortBy: ""
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "ID " + this.$t("pos"),
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_geographyId() {
      return {
        key: "geographyId",
        title: `ID ${this.$t("geography")}`,
        field: "geographyId",
        align: "left",
        width: 120,
        sortBy: ""
      };
    },
    col_geography_name() {
      return {
        key: "geography_name",
        title: `${this.$t("geography")}`,
        field: "geography_name",
        align: "left",
        width: 200,
        sortBy: ""
      };
    },
    col_idPosBrand() {
      return {
        key: "idPosBrand",
        title: `ID ${this.$t("pos")} ${this.$t("brands.brand")}`,
        field: "idPosBrand",
        align: "left",
        width: 200,
        sortBy: ""
      };
    },
    col_pos() {
      return {
        key: "pos_name",
        title: this.$t("pos") + " " + this.$t("brands.name"),
        field: "pos_name",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_name}</span>;
        }
      };
    },
    col_address_label() {
      return {
        key: "address_label",
        title: this.$t("address"),
        field: "address_label",
        align: "left",
        width: 200,
        sortBy: ""
      };
    },
    col_brand() {
      return {
        key: "brand_name",
        title: this.$t("brands.brand"),
        field: "brand_name",
        align: "left",
        width: 200,
        sortBy: ""
      };
    },
    col_chain() {
      return {
        key: "chain_name",
        title: this.$t("chain"),
        field: "chain_name",
        align: "left",
        sortBy: "",
        width: 200
      };
    },
    col_subchain() {
      return {
        key: "subchain_name",
        title: "Sub " + this.$t("chain"),
        field: "subchain_name",
        align: "left",
        sortBy: "",
        width: 200
      };
    },
    col_sub2chain() {
      return {
        key: "sub2chain_name",
        title: "Sub-Sub " + this.$t("chain"),
        field: "sub2chain_name",
        align: "left",
        sortBy: "",
        width: 200
      };
    },
    col_channel() {
      return {
        key: "channel_name",
        title: this.$t("channel"),
        field: "channel_name",
        align: "left",
        sortBy: "",
        width: 200
      };
    },
    col_subchannel() {
      return {
        key: "subchannel_name",
        title: "Sub " + this.$t("channel"),
        field: "subchannel_name",
        align: "left",
        sortBy: "",
        width: 200
      };
    },
    col_sub2channel() {
      return {
        key: "sub2channel_name",
        title: "Sub-Sub " + this.$t("channel"),
        field: "sub2channel_name",
        align: "left",
        sortBy: "",
        width: 200
      };
    },
    col_objectiveValue() {
      return {
        key: "objectiveValue",
        title: "Perfect Store Objectivo Points",
        field: "objectiveValue",
        align: "left",
        width: 200
      };
    },
    col_next_visit_date() {
      return {
        key: "next_visit_date",
        title: "Next Visit",
        field: "next_visit_date",
        align: "left",
        width: 200
      };
    },
    defaultColumns() {
      return [
        {
          title: "",
          children: [
            {
              title: "",
              children: [
                this.col_date,
                this.col_posId,
                this.col_idPosBrand,
                this.col_pos,
                this.col_address_label,
                this.col_chain,
                this.col_subchain,
                this.col_sub2chain,
                this.col_channel,
                this.col_subchannel,
                this.col_sub2channel,
                this.col_brand,
                this.col_userId,
                this.col_user,
                this.col_routeId,
                this.col_route,
                this.col_responsableId,
                this.col_responsable_name,
                this.col_geographyId,
                this.col_geography_name,
                this.col_objectiveValue,
                this.col_next_visit_date
              ]
            }
          ]
        }
      ];
    }
  },
  methods: {
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "surveys") {
        this.filtered_surveys = resp.data;
      } else if (fieldname === "spvs") {
        this.filtered_spvs = resp.data;
      } else if (fieldname === "gpvs") {
        this.filtered_gpvs = resp.data;
      } else if (fieldname === "routes") {
        this.filtered_routes = resp.data;
      } else if (fieldname === "companies") {
        this.filtered_companies = resp.data;
      }
      this.isSearching[fieldname] = false;
    },
    onChangeFilterMainCredentials(fieldname) {
      this.searchValues(fieldname, "");
    },
    onChangeDates() {
      if (
        this.filterMainCredential.startDate > this.filterMainCredential.endDate
      ) {
        this.filterMainCredential.endDate = this.filterMainCredential.startDate;
      }
    },
    async onClickFilter() {
      if (this.$refs.form.validate()) {
        this.getDataFromApi();
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        [
          "unit_price_without_vat",
          "euros_sales",
          "total_pos_sales",
          "total_pos_sales_objective"
        ].includes(cancelFilterKey)
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (cancelFilterKey === "address_label") {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_reporting_columna"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getCompIDFromUUID(uuid, components) {
      let found = components.find(el => el.uuid == uuid);
      if (found) return found.id;
      return null;
    },
    getQuestionIDFromUUID(
      uuid,
      questions,
      foundOriginalSurveys,
      survey,
      survey_component
    ) {
      let idQuestions = [];
      if (Object.keys(questions).length > 0) {
        for (const [questionId, questionItem] of Object.entries(questions)) {
          if (questionItem.uuid == uuid) {
            idQuestions.push(questionId);
          }
        }
      }
      if (survey) {
        let sortedKeys = [];
        if (survey_component?.assignType === "PRODUCT") {
          sortedKeys = survey_component?.sortedProductKeys;
        } else if (survey_component?.assignType === "MATERIAL") {
          sortedKeys = survey_component?.sortedMaterialKeys;
        }
        if (!sortedKeys) sortedKeys = [];
        if (sortedKeys.length > 0) {
          idQuestions.sort((a, b) => {
            const numa = parseInt(a.split("_")[1]);
            const numb = parseInt(b.split("_")[1]);

            const indexa = sortedKeys.indexOf(numa);
            const indexb = sortedKeys.indexOf(numb);

            if (indexa > indexb) {
              return 1;
            }
            if (indexa < indexb) {
              return -1;
            }
            return 0;
          });
        } else {
          idQuestions.sort((a, b) => {
            if (a > b) return 1;
            else if (a < b) return -1;
            return 0;
          });
        }
      }
      return idQuestions;
    },
    generate_new_cols(merged_surveys) {
      this.columns = [];
      let columns = [...this.defaultColumns];
      if (this.sorting && Object.keys(this.sorting).length > 0) {
        let col_sorting = null;
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== "") {
            col_sorting = {
              key,
              value
            };
          }
        }
        for (let i = 0; i < columns[0].children[0].children.length; i++) {
          if (
            col_sorting !== null &&
            col_sorting.key === columns[0].children[0].children[i].key
          ) {
            columns[0].children[0].children[i].sortBy = col_sorting.value;
          } else {
            columns[0].children[0].children[i].sortBy = "";
          }
        }
      }
      if (Object.keys(merged_surveys).length > 0) {
        for (const [surveyId, survey] of Object.entries(merged_surveys)) {
          let survey_push_item = {
            title: survey.name,
            key: `survey_${surveyId}`,
            children: []
          };
          let componentOrder = survey.componentOrder;
          if (componentOrder.length > 0) {
            componentOrder.map(componentUUID => {
              let surveyComponentId = this.getCompIDFromUUID(
                componentUUID,
                Object.values(survey.survey_components)
              );
              if (
                surveyComponentId &&
                Object.keys(survey.survey_components)
                  .map(el => parseInt(el))
                  .includes(surveyComponentId)
              ) {
                let survey_component =
                  survey.survey_components[surveyComponentId];
                let survey_component_push_item = {
                  title: survey_component.name,
                  key: `survey_component_${surveyComponentId}`,
                  children: []
                };
                let questionOrder = survey_component.questionOrder;
                if (questionOrder.length > 0) {
                  questionOrder.map(questionUUID => {
                    let idQuestions = this.getQuestionIDFromUUID(
                      questionUUID,
                      survey_component.survey_questions,
                      this.foundOriginalSurveys,
                      survey,
                      survey_component
                    );
                    if (idQuestions.length > 0) {
                      idQuestions.map(surveyQuestionId => {
                        let survey_question =
                          survey_component.survey_questions[surveyQuestionId];
                        let question_title = survey_question.name;
                        let survey_question_push_item = {
                          align: "center",
                          width: 200,
                          field: `survey_question_value_${surveyQuestionId}`,
                          key: `survey_question_${surveyQuestionId}`
                        };
                        if (survey_question.pro_mat_comp_label) {
                          question_title += ` - ${survey_question.pro_mat_comp_label}`;
                        }
                        survey_question_push_item.title = question_title;
                        survey_component_push_item.children.push(
                          survey_question_push_item
                        );

                        if (survey_question?.isSumPS) {
                          let survey_question_objective_push_item = {
                            align: "center",
                            width: 200,
                            field: `survey_question_objective_value_${surveyQuestionId}`,
                            title: `Objectivo - ${question_title}`,
                            key: `survey_question_objective_${surveyQuestionId}`
                          };
                          survey_component_push_item.children.push(
                            survey_question_objective_push_item
                          );
                          let survey_question_ps_push_item = {
                            align: "center",
                            width: 200,
                            field: `survey_question_ps_value_${surveyQuestionId}`,
                            title: `Puntos Perfect Store - ${question_title}`,
                            key: `survey_question_ps_${surveyQuestionId}`
                          };
                          survey_component_push_item.children.push(
                            survey_question_ps_push_item
                          );
                          let survey_question_max_ps_push_item = {
                            align: "center",
                            width: 200,
                            field: `survey_question_max_ps_value_${surveyQuestionId}`,
                            title: `Max Puntos Perfect Store - ${question_title}`,
                            key: `survey_question_max_ps_${surveyQuestionId}`
                          };
                          survey_component_push_item.children.push(
                            survey_question_max_ps_push_item
                          );
                        }
                      });
                    }
                  });
                }
                survey_push_item.children.push(survey_component_push_item);
              }
            });
          }
          columns.push(survey_push_item);
        }
      }
      this.columns = columns;
    },
    async getDataFromApi() {
      this.isMockupColumns = false;
      this.isLoadingTableData = true;
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#datatable_reporting_columna .filterable-column"
      });
      let fetch_url = "reporting/columna";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const resp = await ApiService.post(fetch_url, form_data);
        this.merged_surveys = resp.merged_surveys;
        this.foundOriginalSurveys = resp.foundOriginalSurveys;
        this.generate_new_cols(this.merged_surveys);
        this.isMockupColumns = true;
        this.data = resp.data;
        this.totaldata = resp.total;
        this.isLoadingTableData = false;
      } catch (error) {
        this.isLoadingTableData = false;
      }
    },
    async downloadExcel() {
      if (!this.$refs.form.validate()) return;
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#datatable_reporting_columna .filterable-column"
      });
      let fetch_url = "reporting/columna/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      try {
        const response = await ApiService.post(fetch_url, form_data, {
          responseType: "blob"
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        console.log("error - ", error);
        logError("Excel supports columns from 1 to 16384");
        this.close();
      }
    },
    async init() {
      this.filterable_col_list = Object.keys(this.filterCriteria);
      await this.searchValues("brands", "");
      await this.searchValues("surveys", "");
      await this.searchValues("spvs", "");
      await this.searchValues("gpvs", "");
      await this.searchValues("routes", "");
      await this.searchValues("companies", "");
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
